<template>
  <b-card :title="''">
    <template v-if="isLoading">
      <div class="text-center m-5 d-flex justify-content-center">
        <b-spinner variant="success" style="width: 3rem; height: 3rem;"></b-spinner>
      </div>
    </template>
    <template v-else>
      <apexchart width="100%" :height="height" type="bar" :options="options" :series="series"></apexchart>
    </template>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      isLoading: false,
      height: this.$store.getters["account/accountData"].role == 'admin'? '300' : '1000',
      apiUrl: {
        regency: process.env.VUE_APP_API_URL + "regencies?limit=1000&need_member=1&province_id=950b9d3a-c996-4207-97e7-5a2858981979",
        district: process.env.VUE_APP_API_URL + "ditricts?limit=1000&need_member=1&regency_id=" + this.$store.getters["account/accountData"].regency_id,
      },
      options: {
        chart: {
          id: 'vuechart-example'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },
      series: [{
        name: 'Total Anggota',
        data: []
      }],
      colors: ["#00FF00"]
    }
  },
  mounted() {
    this.getDashboard()
  },
  methods: {
    getDashboard () {
      this.isLoading = true
      let url = ''
      if ( this.$store.getters["account/accountData"].role == 'admin') {
        url = process.env.VUE_APP_API_URL + "districts?limit=1000&need_member=1&regency_id=" + this.$store.getters["account/accountData"].regency_id
      } else {
        url = process.env.VUE_APP_API_URL + "regencies?limit=1000&need_member=1&province_id=950b9d3a-c996-4207-97e7-5a2858981979"
      }
      this.$http.get(url)
      .then((response) => {
        response.data.data.forEach(data => {
          this.options.xaxis.categories.push(data.name)
          let listMember = []
          data.members.forEach(dataMember => {
            if (dataMember.is_verified) {
              listMember.push(dataMember)
            }
          })
          this.series[0].data.push(listMember.length)
        })
        this.isLoading = false
      })
    }
  },
}
</script>